let ProjectPHP
    = (function () {

    function hello() {
        console.log('The ProjectPHP JavaScript works! 🙂');
    }

    return {
        hello: hello    // publicly available as: ProjectPHP.hello()
    };
})();

export default ProjectPHP;

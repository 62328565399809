

require('./bootstrap');
window.Noty = require('noty');
Noty.overrideDefaults({
    theme: 'bootstrap-v4',
    type: 'warning',
    layout: 'center',
    modal: true,
});

$('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    html : true,
}).on('click', '[data-toggle="tooltip"]', function () {
    // hide tooltip when you click on it
    $(this).tooltip('hide');
});

// Make 'ProjectPHP' accessible inside the HTML pages
import ProjectPHP from "./ProjectPHP";
window.ProjectPHP = ProjectPHP;
// Run the hello() function
ProjectPHP.hello();
require('bootstrap-select')
